import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, NavigationStart, Params, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import {LoginInfoComponent} from "../../user/login-info/login-info.component";
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import {LayoutService} from "@app/shared/layout/layout.service";
import { UserService } from '@app/core/services';
import { environment } from '@env/environment';
import { CommonService } from '@app/core/services/common.service';
import { NavigationService } from './navigation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { JoyrideOptions } from 'ngx-joyride/lib/models/joyride-options.class';
import { JoyrideService } from 'ngx-joyride';
import { TutorialEventsService } from '@app/core/services/tutorial-events.service';
import { Tutorial } from './tutorial.model';
import { I18nService } from '@app/shared/i18n';
import * as moment from 'moment';
import { EmitService } from '@app/ts/services/emit.service';
import { ModalDirective } from 'ngx-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NumberPipe } from '@app/shared/pipes/number.pipe';
import { LandingService } from '@app/features/landing/landing.service';
import { Observable } from 'rxjs';
declare var $: any;
@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html',
  styleUrls:['./navigation.component.scss'],
  providers: [NumberPipe],
  host: {
    '(body:dblclick)': 'ondblClick($event)',
    '(window:keyup)': 'onKeyPress($event)'
  }
})
export class NavigationComponent implements OnInit {
  $contract: Observable<boolean>;
  netcomId: any;
  navigationList:any = [];
  isNavLoading: boolean;
  appDataSubscription: Subscription;
  appCode: string;
  appId: number;
  showDefault: boolean;
  appPath: string;
  userName = "";
  accountId:any;
  imageData:any;
  inputOpen: boolean;
  stepContent: any;
  isFirstTime: boolean = false;
  welcome_step: Tutorial = new Tutorial();
  abcd: boolean = false;
  companyName: string;
  lanId: any;
  subscriptionData:any = [];
  currentPlan='';
  countData:any;
  netcom_id;
  isCountsLoaded:boolean = false;
  langChangeSubscription: any;
  permissions: any;
  showConnectionDetails:boolean=true;
  tutorailLoad: boolean = false;
  updateCountsSub: Subscription;
  eBillData:any = {};
  currency:string = '';
  @ViewChild('tutorialModal') public tutorialModal:ModalDirective;
  tutorial_select: FormGroup = new FormGroup({});
  ebillPermission: boolean = false;
  tourData: any = {};
  ebill_welcome: any;
  app_module: any = 'EDI';
  enableNav: boolean;
  ebillErrorPrice:any = environment.EBILL_ERROR_PRICE;
  module:string;
  ebillDataPresent: boolean = false;
  
  constructor(private appService: AppService, public router: Router,private route: ActivatedRoute,private httpClient: HttpClient,
      private layoutService: LayoutService,private userService:UserService,private commonService: CommonService,private ts: TutorialEventsService,
      private navigationService:NavigationService,private _sanitizer: DomSanitizer,private joyrideService: JoyrideService,private i18nService: I18nService, private emitService: EmitService,private _appService: AppService, private fb: FormBuilder, private numberPipe: NumberPipe, private landingService: LandingService) {
      this.netcom_id = localStorage.getEncryptedItem('netcomId');
      let permissionCookie = this.commonService.getCookie(environment.permissions);
      this.permissions = this.commonService.decrypt(decodeURIComponent(permissionCookie)).split(',');      
      this.ebillPermission = this.permissions.toString().indexOf("ebill.settings.")!=-1 ? true : false;
      this.app_module = this.ebillPermission ?  'EBILL' : 'EDI';
      var self = this;
      $(document).ready(function($) {
        if (window.history && window.history.pushState) {
          $(window).on('popstate', function() {
            if( localStorage.getEncryptedItem('hideTutorial')){
              if( localStorage.getEncryptedItem('tutorialStatus') == 'running'){
                self.joyrideService.closeTour();
                localStorage.setEncryptedItem('tutorialStatus','close');
              }
            }
          });
        }
      });
      $('body').off('mouseenter', '.transactionValueRecevier').on('mouseenter', '.transactionValueRecevier', function (event) {
        event.stopPropagation();
        // let style = "height:10px !important;padding:5px";
        let closest_fileType = $(this);
        let planPackageName = '';
        let planPackageContent = '';
        planPackageName = self.countData['plan_name'] ? `<div class='ac_div'> <span>${self.i18nService.getTranslation('user_account_type')}:</span></div>
        <div class='ac_typeDiv'> <span  class='common_detail'>${self.countData['plan_name']}</span></div>` : (self.countData['package_name'] ? `<div class='ac_div'> <span>${self.i18nService.getTranslation('user_dashboard_recent_package')}:</span></div>
        <div class='ac_typeDiv'> <span  class='common_detail'>${self.countData['package_name']} </span></div>`:``)
        planPackageContent = self.countData['plan_name'] ? `<div class='ac_div'> <span>${self.i18nService.getTranslation('user_dashboard_transaction')}:</span></div>
          <div class='ac_typeDiv'> <span  class='common_detail'>${self.countData['txe_count']} ${self.i18nService.getTranslation('common_from')} ${self.countData['txe_limit']}</span></div>` : (self.countData['package_name'] ? `<div class='ac_div'> <span>${self.i18nService.getTranslation('user_dashboard_balance')}:</span></div>
          <div class='ac_typeDiv'> <span  class='common_detail'>${self.countData['balance']} </span></div>`:``)
          $(closest_fileType).append(
            "<div class='idTooltip  idTooltip_edi' style='left:65%; height:auto; text-align: left; padding-left: 5px; width:200px;' >" +
            planPackageName+
           "<div class='ac_div'> <span>"+self.i18nService.getTranslation('connections_Number')+":</span></div>"+
          "<div class='ac_typeDiv'> <span  class='common_detail'>"+self.countData['bc_count']+"</span></div>"+
          planPackageContent+
            "</div>"
          );
      });
      $('body').off('mouseleave', '.transactionValueRecevier').on('mouseleave', '.transactionValueRecevier', (event) => {
        $(event.currentTarget).parent().find(".idTooltip_edi").remove();
      });
      $('body').off('mouseenter', '.ebill_details').on('mouseenter', '.ebill_details', function (event) {
        event.stopPropagation();
        // let style = "height:10px !important;padding:5px";
        let closest_fileType = $(this);
        // var cost_per_transaction = self.eBillData.zeroPrice ?"<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost_per_transaction')+": </span> <span  class='common_detail'>"+self.currency + ' '+self.numberPipe.transform(self.eBillData.zeroPrice, this.lanId, false)+ "</span></div>": "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost_per_transaction')+": </span> <span  class='common_detail'>"+self.currency + ' '+self.numberPipe.transform(self.eBillData.price, this.lanId, false)+ "</span></div>"
        // console.log(cost_per_transaction);
          
        $(closest_fileType).append(
            "<div class='idTooltip idTooltip_ebill' style='left:65%; height:auto; text-align: left; padding-left: 5px; width:300px;z-index: 1000' >" +
            "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_transaction')+": </span> <span  class='common_detail'>"+self.eBillData.count+"</span></div>"+
            // "<div class='ac_typeDiv'><span  class='common_detail'>"+self.eBillData.count+"</span></div>"+
          //  "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost_per_transaction')+": </span> <span  class='common_detail'>"+self.currency + ' '+self.numberPipe.transform(self.eBillData.price, this.lanId, false)+ "</span></div>"+
          // "<div class='ac_typeDiv'> <span  class='common_detail'>"+self.currency + ' '+self.numberPipe.transform(self.eBillData.price, this.lanId, false)+ "</span></div>"+
          // cost_per_transaction+
          // "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost_per_error_transaction')+": </span><span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(self.ebillErrorPrice, this.lanId, false)+' '+"</span></div>"+
          // "<div class='ac_typeDiv'> <span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(self.ebillErrorPrice, this.lanId, false)+' '+"</span></div>"+
          "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_total_cost_for_error_transaction')+": </span> <span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.errorCount * self.ebillErrorPrice)), this.lanId, false)+' '+"</span></div>"+
          "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_total_cost_for_transaction')+": </span><span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.successCount * self.eBillData.price)), this.lanId, false)+' '+"</span></div>"+
          "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost')+": </span><span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.successCount * self.eBillData.price)+(self.eBillData.errorCount * self.ebillErrorPrice)), this.lanId, false)+' '+"</span></div>"+
          // "<div class='ac_typeDiv'> <span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.successCount * self.eBillData.price)+(self.eBillData.errorCount * self.ebillErrorPrice)), this.lanId, false)+' '+"</span></div>"+
            "</div>"
          );
        
      });
      $('body').off('mouseleave', '.ebill_details').on('mouseleave', '.ebill_details', (event) => {
        $(event.currentTarget).parent().find(".idTooltip_ebill").remove();
      });
      $('body').off('mouseenter', '.ebill_amount').on('mouseenter', '.ebill_amount', function (event) {
        event.stopPropagation();
        let closest_fileType = $(this);
        $(closest_fileType).append(
          "<div class='idTooltip idTooltip_ebill' style='left:40%; height:auto; text-align: left; padding-left: 5px; width:300px;z-index: 1000;bottom:calc("+$(".ebill_amount").height()+"px - 8px)' >" +
          "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_total_cost_for_error_transaction')+": </span> <span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.errorCount * self.ebillErrorPrice)), this.lanId, false)+' '+"</span></div>"+
          "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_total_cost_for_transaction')+": </span><span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.successCount * self.eBillData.price)), this.lanId, false)+' '+"</span></div>"+
          "</div>"
        );
        
      });
      $('body').off('mouseleave', '.ebill_amount').on('mouseleave', '.ebill_amount', (event) => {
        $(event.currentTarget).parent().find(".idTooltip_ebill").remove();
      });
      if ((this.router.url.indexOf("/ebill/") !== -1)) {
        this.showConnectionDetails=false;
        this.getEbillNavigationList();
        // $('body').addClass('setting-menu');      
      }
      else{
        this.showConnectionDetails=true;
        this.getNavigationList();   
        // $('body').removeClass('setting-menu'); 
      }  
     
    $("body li").first().addClass("active");
    setTimeout(() => {
      this.getTutorialData();
    }, 100);
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => this.onLangChange(langObj));
  }
  ngOnInit() {
    localStorage.setEncryptedItem('tutorialStatus','close');
    if( localStorage.getEncryptedItem('hideTutorial') == 'false'){
      this.getTutorialData();
    }
    this._appService._navigationNotify$.subscribe(data => { 
      if(data.toString()=='true') {
        this.module != 'ebill' ? this.getEbillNavigationList() : '';
      //  $('body').removeClass('setting-menu');
      }else if(data.toString()=='false') {
        this.module != 'edi' ? this.getNavigationList() : '';
        // $('body').addClass('setting-menu');
      }
      // else if(data.toString()=='ebill') {
      //   this.getEbillNavigationList();
      //   // $('body').addClass('setting-menu');
      // }
    })
    this.getCounts();
    if( this.ebillPermission ) this.getBillerInfo();
    this.lanId = this.commonService.getCurrentLanguageCode();
    this.ts.cast.subscribe(user=> {
      if( user){
        this.getTutorialData();
      }
    });
    this.updateCountsSub = this.emitService.updateCountsSubject.subscribe( data => {
      if( data){
        this.isCountsLoaded = false;
        this.getCounts();
      }
      
    })
    this.appDataSubscription = this.layoutService.subscribeAppDataChange(data => {
      let appCode = data.code;
      this.appId = data.id;
      this.appPath = data.path;
      this.showDefault = data.showDefault;
      if (this.appCode !== appCode || this.appId!=undefined) {
        this.appCode = appCode;
      }
    }); 
     let userCookie = this.commonService.getCookie(this.commonService.encrypt(environment.userInfo));
     let userData = userCookie ? JSON.parse(this.commonService.decrypt(userCookie)) : ""; 
    //  this.userName = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].username : '';
    // let companyName = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].companyDetails : '';
    // localStorage.getEncryptedItem('userData') ? this.companyName = companyName.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'] : '';
    let permissionCookie = this.commonService.getCookie(environment.permissions);
    this.permissions = permissionCookie ? this.commonService.decrypt(permissionCookie):'';
    this.userName = userData.userName ? userData.userName : "";
    this.companyName = userData.companyName ? userData.companyName: "";
    let fetchProfileData = new FormData();
    this.accountId = localStorage.getEncryptedItem('accId');
    fetchProfileData.append("accountId", this.accountId);
    let object = this.commonService.createObject(fetchProfileData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    this.navigationService.getUserImg(encrData)
    .subscribe(data => {
      if(data['status'])
      this.imageData = this._sanitizer.bypassSecurityTrustResourceUrl(data['data']);
    })
    let formDataEbill = new FormData();
    formDataEbill.append('companyId',localStorage.getEncryptedItem('partyID'));
    formDataEbill.append('netcomId',localStorage.getEncryptedItem('netcomId'));
    formDataEbill.append('roleAccId',localStorage.getEncryptedItem('accId'));
    formDataEbill.append('rolePartyId',localStorage.getEncryptedItem('partyID'));
    let objectEbill = this.commonService.createObject(formDataEbill);
    let tokenEbill = this.commonService.createToken(objectEbill);
    let encryptedTokenEbill = this.commonService.encrypt(tokenEbill);
    let encrDataEbill = new FormData();
    encrDataEbill.append("encrToken", encryptedTokenEbill);
    this.navigationService.getEBillData(encrDataEbill).subscribe(res=>{
      if(res.status){
        this.eBillData = res['data'][0];
        this.currency = localStorage.getEncryptedItem('userData')?JSON.parse(localStorage.getEncryptedItem('userData'))[0].currency:'';
      }
    })

    setTimeout(() => {
      let tutorialStatus = localStorage.getEncryptedItem('hideTutorial') == 'true' ? true : false;
      this._appService.contract$.subscribe(data => { 
        if(data.toString()=='true') {
          if(!tutorialStatus)
         this.openPopup();
        }
      })
    },500);
  }
  getNavigationList(){
    // this.permissions = localStorage.getEncryptedItem('permissions') ? this.commonService.decrypt(localStorage.getEncryptedItem('permissions')):'';    
    // this.userName = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].username : '';
    // let companyName = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].companyDetails : '';
    // localStorage.getEncryptedItem('userData') ? this.companyName = companyName.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'] : '';
    this.isNavLoading = true;
    this.navigationList = [];
      this.httpClient.get("assets/api/jsonData/navigation.json").subscribe(data =>{ 
        this.module = 'edi'; 
        let navData = this.manipulateTreeData(data['DATA']);
        this.navigationList =  navData; 
          this.isNavLoading = true; 
          this.enableNav = true;
          setTimeout(() => {
            this.isNavLoading = false;
          })
      })
  }
  getEbillNavigationList(){
    this.isNavLoading = true;
    this.navigationList = [];
    this.httpClient.get("assets/api/jsonData/ebill_nav.json").subscribe(data =>{ 
      this.module = 'ebill'; 
      let navData = this.manipulateTreeData(data['DATA']);
      this.navigationList =  navData; 
      this.isNavLoading = true;
      if( localStorage.getEncryptedItem('showEbill') == 'true'){
        this.enableNav = true;
      }
      else{
        this.enableNav = false;
      }      
      setTimeout(() => {
        this.isNavLoading = false;
      }); 
    })
  }

  manipulateTreeData(tree){
    let arrTree = [];
    let sortedArr = [];
    let retSortedArr = [];
    for (let i in tree) {
      let node = tree[i];
      if(typeof node === 'object'){
        let manipulatedNode = this.manipulateSettingsNode(node);
        arrTree.push(manipulatedNode);
        let pos = manipulatedNode['POS'];
        if (pos) {
          pos = parseInt(pos);
          sortedArr[pos] = manipulatedNode;
        }
      }  
    }
    for (let j in sortedArr) {
      if (sortedArr[j]) {
        retSortedArr.push(sortedArr[j]);
      }
    }
    if (arrTree.length === retSortedArr.length) {
      return retSortedArr;
    }
    return arrTree;
  }
  manipulateSettingsNode(node) {
    let manipulatedNode = {
      children: []
    };
    let sortedNode = {
      children: []
    };
    let retSortedNode = {
      children: []
    };
    for (let i in node) {
      let childData = node[i];
      if (typeof (childData) === 'object') {
        let gManiuplatedNode = this.manipulateSettingsNode(childData);
        manipulatedNode.children.push(gManiuplatedNode);
        let pos = gManiuplatedNode['POS'];
        if (pos) {
          pos = parseInt(pos);
          sortedNode.children[pos] = gManiuplatedNode;
        }
      } else {
        manipulatedNode[i] = childData;
        sortedNode[i] = childData;
        retSortedNode[i] = childData;
      }
    }
    for (let j in sortedNode.children) {
      if (sortedNode.children[j]) {
        retSortedNode.children.push(sortedNode.children[j]);
      }
    }
    if (manipulatedNode.children.length === retSortedNode.children.length) {
      return retSortedNode;
    }
    return manipulatedNode;
  }
  setNavid(data,module,appDetail){
    var tempNavId = '';
    if(data == 'init'){
      if(this.navigationList.length != 0){
        this.navigationList.forEach(element => {
          if(element.children.length > 0){
            element.children.forEach(ele => {
              if(ele.children.length > 0){
                ele.children.forEach(e => {
                  if(e.children.length>0){
                    e.children.forEach(el => {
                      if(el.MODULE == window.location.pathname){
                        this.layoutService.setNavId(el.ID,el.MODULE);
                      }
                    });
                  }
                  if(e.MODULE == window.location.pathname){
                    this.layoutService.setNavId(e.ID,e.MODULE);
                  }
                });
              }
              if(ele.MODULE == window.location.pathname){
                this.layoutService.setNavId(ele.ID,ele.MODULE);
              }
            });
          }
          if(element.MODULE == window.location.pathname){
            this.layoutService.setNavId(element.ID,element.MODULE);
          }
        });
      }
      else if(appDetail){
        this.layoutService.setNavId(appDetail.id,appDetail.path);
      }
    }
    else if(module != ''){
      this.layoutService.setNavId(data,module)

    }
  }

  goToProfile() {
    window.open(environment.profile_URL, '_self');
    return false;
  }

  goToAdmin() {
    window.open(environment.ADMIN_URL, '_self')
    return false;
  }
  
  startTour(from){
    this.stepContent = this.tourData[this.app_module];
    let tourData = this.stepContent["stepData"];
    let tourSteps = this.stepContent["steps"];
    let activeIndex = -1;
    let configIndex = -1;
    let ebillCreateIndex = -1;
    let ebillEditIndex = -1;
    let permissionCookie = this.commonService.getCookie(environment.permissions);
    let permissions = this.commonService.decrypt(decodeURIComponent(permissionCookie)).split(',');
    Object.keys(this.stepContent["stepData"]).forEach( el =>{
      if(!tourData[el].defaultShow){
        let path = tourData[el].path;
        if( tourSteps.indexOf(path) != -1 ){
          tourSteps = tourSteps.filter(function(e) { return e !== path });
        }
      }
      if( this.app_module == 'EBILL' ){
        if( permissions.find(a =>a.includes("ebill.")) && el == 'ebill_activate'){
          activeIndex = tourSteps.indexOf(tourData[el].path);
        }
        else{
          configIndex = tourSteps.indexOf(tourData[el].path);
        }
        if( el == 'ebill_biller_create' ){
          ebillCreateIndex = tourSteps.indexOf(tourData[el].path);
        }
        if( el == 'ebill_biller_edit' ){
          ebillEditIndex = tourSteps.indexOf(tourData[el].path);
        }
      }     
      
    });
    if( activeIndex > -1 || configIndex > -1){
      if( permissions.find(a =>a.includes("ebill.")) ){
        tourSteps.splice(1,activeIndex);
      }
      else{
        tourSteps.length(configIndex+1);
      }
    }
    if( ebillCreateIndex > -1 && this.ebillDataPresent){
      tourSteps.splice(tourSteps.indexOf('ebill_biller_create@ebill/biller'),1);
      ebillCreateIndex = -1;      
    }
    if( ebillEditIndex > -1 && !this.ebillDataPresent){
      tourSteps.splice(tourSteps.indexOf('ebill_biller_edit@ebill/biller'),1);
      ebillEditIndex = -1;      
    }
    let rideOptions: JoyrideOptions = {
      steps: tourSteps, 
      showCounter: true, 
      stepDefaultPosition: 'bottom', 
      showPrevButton: true, 
      waitingTime: 1000,
      themeColor: '#080a0a'
    }
    // if(!localStorage.getEncryptedItem('hideTutorial'))
    // this.welcome_step = JSON.parse(localStorage.getEncryptedItem('stepContent'))["stepData"]["bexio_welcome"];
    this.welcome_step = JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getEncryptedItem('stepContent')))))['EDI']["stepData"]["bexio_welcome"];
    this.ebill_welcome = this.ebillPermission ? JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getEncryptedItem('stepContent')))))['EBILL']["stepData"]["ebill_welcome"] : '';
    this.joyrideService.startTour( rideOptions)
    .subscribe(
        step => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          if( from == 'click')
            localStorage.setEncryptedItem('tutorialStatus','running');
        }, e => { }, 
        () => {
          this.completedTour();
      } );
      this.joyrideService.isTourInProgress();
      
  }
  completedTour(){
    localStorage.setEncryptedItem('tutorialStatus','close');
    localStorage.setEncryptedItem('hideTutorial','true');
    this.emitService.subscribeTutorialStatus(true);
    this.joyrideService.closeTour();
  }
  doneTour(){
    let tutorialStatus = localStorage.getEncryptedItem('hideTutorial') == 'true' ? true : false;
    localStorage.setEncryptedItem('tutorialStatus','close');
    let formData = new FormData();
    formData.append('accId',localStorage.getEncryptedItem('accId'));
    formData.append('roleAccId',localStorage.getEncryptedItem('accId'));
    formData.append('rolePartyId',localStorage.getEncryptedItem('partyID'));
    let object = this.commonService.createObject(formData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken',encryptedToken);
    this.navigationService.updateTutorialStatus(encrData).subscribe( data => {
      if( data.status){
        localStorage.setEncryptedItem('tutorialStatus','close');
        this.joyrideService.closeTour();
        localStorage.setEncryptedItem('hideTutorial',true);
        this.tutorialModal.hide();
        this.emitService.subscribeTutorialStatus(true);
      }
    });
  }


  ondblClick(e) {
    let permission = this.commonService.decrypt(this.commonService.getCookie(environment.permissions)).split(',');
    if( !permission.includes('superadmin.info.edit')){
      e.preventDefault();
      e.stopImmediatePropagation();
      return true;
    }
    var target = $(e.target);
    let a = Object.keys(target.parents());
    let b = [];  
    a.forEach( e => b.push(target.parents()[e].className));
    if (target.length > 0 && (target[0].className == 'editInput' || b.includes('editInput'))) {
      let textConteny =  $("span.editInput").text();
      // $("textarea.in").val(textConteny)
      $('.editInput').css('display','none');
      $("textarea.in").css('display','block');
      $('.btn-save').css('display','block');
    }
    else if( target.length > 0 && target[0].className == 'joyride-step__title'){
      let titleText = $("div.joyride-step__title").text();
      if($('.tour-title-input').length == 0){
        $('.joyride-step__title').parent().append('<input type="text" class="form-control tour-title-input input-field" />');
      }
      else{
        $('.tour-title-input').css('display','block')
      }
      
      $('.tour-title-input').val(titleText);
      $('.btn-save').css('display','block');
      $('.joyride-step__title').css('display','none');
    }
  }

  onKeyPress(e){
      
    if(!$("textarea.in").is(":visible") && !$("input.tour-title-input").is(":visible") ){
      if( e.keyCode == 13){
        $('.joyride-step__next-container .joyride-button').click();
      }
      
    }
    if($('.joyride-step__container').is(":visible")){
      if(e.keyCode == 37 || e.keyCode == 39){
        e.stopImmediatePropagation();
        return
      }
    }
  }
  saveContent(){
    let permission = this.commonService.decrypt(this.commonService.getCookie(environment.permissions)).split(',');
    if( !permission.includes('superadmin.info.edit')){
      return true;
    }
    let stepId = $("textarea.in").attr("id");
    let saveContent;
    Object.keys(this.tourData).forEach( el => { 
      if( Object.keys(this.tourData[el]['stepData']).indexOf(stepId) != -1) {
        saveContent = this.tourData[el]['stepData'][stepId];
      }
    });
    let desc,title;
    if( $("input.tour-title-input").is(":visible") ){
      if($("input.tour-title-input").val())
        title = $("input.tour-title-input").val();
      else
        return;
    }
    else{
      title = saveContent.title
    }
    if($("textarea.in").is(":visible")){
      if( $("textarea.in").val() )
        desc = $("textarea.in").val();
      else
        return;
    }
    else{
      desc = saveContent['description'];
    }
    if($("textarea.in").is(":visible") || $("input.tour-title-input").is(":visible")){
        this.tutorailLoad = true;
        this.inputOpen = false;
        let a = $("textarea.in").val();
        let b = $("input.tour-title-input").val();
        // if( a != "" || b != ""){
          let formData = new FormData();
          let lanId = this.commonService.getCurrentLanguageCode();
          lanId = lanId ? lanId : 'en';
          formData.append('language',lanId.toUpperCase());
          formData.append('id',saveContent.cid);
          formData.append('title',title ? title : saveContent.title);
          formData.append('description',desc ? desc : saveContent['description']);
          formData.append('type','tour');
          formData.append('roleAccId',localStorage.getEncryptedItem('accId'));
          formData.append('rolePartyId',localStorage.getEncryptedItem('partyID'));
          let object = this.commonService.createObject(formData);
          let token = this.commonService.createToken(object);
          let encryptedToken = this.commonService.encrypt(token);
          let encrData = new FormData();
          encrData.append('encrToken',encryptedToken);
          this.navigationService.updateTour(encrData).subscribe( data => {
            this.ts.editUser(true);
            if( title){
              $('.joyride-step__title').text(title);
              $('.joyride-step__title').css('display','block');
              $('.tour-title-input').css('display','none')
            }
            if( desc){
              $("span.editInput").text(desc);
              $('.editInput').css('display','block');
              $("textarea.in").css('display','none');
              
            }
            $('.btn-save').css('display','none');
            setTimeout(() => {
              this.tutorailLoad = false;
            },100);
          });
        // }
        
      }
  }
  getTutorialData(){
    if( Object.keys(this.tourData).length != 0 ){ return;}
    this.abcd = false;
    let formData = new FormData();
    let lanId = this.commonService.getCurrentLanguageCode();
    lanId = lanId ? lanId : 'DE';
    formData.append('language',lanId.toUpperCase());
    formData.append('type','tour');
    formData.append('fk_application_version',environment.APPLICATION_VERSION);
    formData.append("partyId", localStorage.getEncryptedItem('partyID'));
    formData.append("accId", localStorage.getEncryptedItem('accId'));
    formData.append('roleAccId',localStorage.getEncryptedItem('accId'));
    formData.append('rolePartyId',localStorage.getEncryptedItem('partyID'));
    let object = this.commonService.createObject(formData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken',encryptedToken);
    this.navigationService.getTourListData(encrData).subscribe( data => {
      this.stepContent =  data["data"];
      this.tourData = {};
      this.tourData = this.stepContent["tourData"];
      // Object.keys(this.stepContent['stepData']).forEach((el) => {
      //   this.tourData[this.stepContent['stepData'][el].appmodule] = {
      //     stepData: {},
      //     steps: [],
      //   };
      // });
      // Object.keys(this.stepContent['stepData']).forEach((el) => {
      //   let tourModule = this.stepContent['stepData'][el].appmodule;
      //   var obj = {};
      //   obj[el] = this.stepContent['stepData'][el];
      //   Object.assign(this.tourData[tourModule]['stepData'],obj);
      //   this.tourData[tourModule]['steps'].push(
      //     this.stepContent['stepData'][el].path
      //   );
      // });
      localStorage.setEncryptedItem('stepContent',btoa(unescape(encodeURIComponent(JSON.stringify(this.tourData)))));
      this.stepContent = this.tourData[this.app_module];
      this.welcome_step = this.tourData['EDI']['stepData']['bexio_welcome'];
      this.ebill_welcome = this.ebillPermission ? this.tourData['EBILL']['stepData']['ebill_welcome'] : "";
      this.navigationService.setTourData(this.tourData);
      this.ts.chageData(true);
      setTimeout(() => {
        this.abcd = true;
      });
    })
  }
  goToSupport() {
    window.open(environment.SUPPORT_URL, environment.SUPPORT_URL)
    return false;
  }
  // getPlanData(){
  //   let formData = new FormData();
  //   formData.append('companyId',localStorage.getEncryptedItem("partyID"));
  //   formData.append("language",this.lanId.toUpperCase());
  //   let object = this.commonService.createObject(formData);
  //   let token = this.commonService.createToken(object);
  //   let encryptedToken = this.commonService.encrypt(token);
  //   let encrData = new FormData();
  //   encrData.append("encrToken", encryptedToken);
  //   this.navigationService.getPlanData(encrData).subscribe( data => {
  //       this.subscriptionData = data['data'];
  //       for (let index = 0; index < this.subscriptionData.length; index++) {
  //         if(this.subscriptionData[index].status && this.subscriptionData[index].type == 'plan')
  //         this.currentPlan = this.subscriptionData[index].apnamelang;
  //         // if(this.subscriptionData[index].status && this.subscriptionData[index].apcname == "PDF2XML Services")
  //         // this.pdfxmlStatus = true
  //       }
  //   });
  // }

  getCounts() {
    let formData = new FormData();
    formData.append('partyId', localStorage.getEncryptedItem('partyID'));
    formData.append('language', this.lanId.toUpperCase())
    let object = this.commonService.createObject(formData)
    let token = this.commonService.createToken(object)
    let encryptedToken = this.commonService.encrypt(token)
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    this.navigationService.getPlanPackageCount(encrData).subscribe(res=>{
      this.countData = res['data'][0];
      this.isCountsLoaded = true;
      // this.currentPlan = this.countData?.plan_name || this.countData?.package_name;
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.langChangeSubscription.unsubscribe();
    this.joyrideService ? this.joyrideService.closeTour(): "";
  }

    onLangChange(langObj) {
    // this.loadTable = true;
    this.lanId = this.commonService.getCurrentLanguageCode();
    moment.locale(this.lanId);
    // this.getEdiPartnerAndProcessList();
    setTimeout(() => {
      // this.loadTable = false;
      // this.getInfo();
      // this.initDatePicker();
      // this.getPlanData();
      
    });
    this.initForm();
    langObj.isHandled = true;
  }
  changePlan(){
    window.open(localStorage.getEncryptedItem('BILLING_URL')+'user-plans', '_self')
  }
  // goTo() {
  //   window.open(localStorage.getEncryptedItem('BILLING_URL'), '_self');
  // }
  initForm(){
    let appModule = this.ebillPermission ? 'EBILL' : 'EDI';
    this.tutorial_select = this.fb.group({
      tutorial_module: [appModule]
    });
  }
  openPopup() {
    localStorage.setEncryptedItem('tutorialStatus','running');
    this.tutorialModal.show();
  }
  openTutorial(from){
    localStorage.setEncryptedItem('tutorialStatus','running');
    this.tutorial_select;
    this.stepContent = this.tourData[this.app_module];
    this.tutorialModal.hide();
    this.stepContent = this.tourData[this.app_module];
    // this.tutorialService.editUser(true);
    setTimeout(() => {
      this.startTour(from);
    });
    
  }
  changeAppModule(appModule,event){
    $('.app-tut').removeClass('active');
    $(event.currentTarget).addClass('active');
    this.app_module = appModule;
    this.stepContent = this.tourData[appModule];
    // this.stepContent = this.tourData[appModule];
  }
  closeModal(){
    localStorage.setEncryptedItem('tutorialStatus','close');
    this.emitService.subscribeTutorialStatus(true);
    this.tutorialModal.hide();
  }

  getBillerInfo() {
    let sectorData = new FormData();
    sectorData.append('netcomId', localStorage.getEncryptedItem('netcomId'));
    let object = this.commonService.createObject(sectorData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken', encryptedToken);
    this.landingService.getBillerDetails(encrData).subscribe(res => {
      if (res['message'] != "no_data_found" && res["data"][0].billerid) {
        this.ebillDataPresent = true;
      }
      else
        this.ebillDataPresent = false;
    })
  }
}
